import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import moment from 'moment'
import { Container, Row, Col, Button } from 'react-bootstrap'
import Wrapper from '../../shared/wrapper/wrapper'
import Header from '../../shared/header/header'
import Footer from '../../shared/footer/footer'
import Step from './step/step'
import Swal from 'sweetalert2'

import { getUserService } from '../../../core/actions/userActions'
// import { startStripeSession } from '../../../core/actions/processActions'
import { processPaypalPayment } from '../../../core/actions/processActions'
import { verifyTokenEnd, userLogout, setAuthToken, verifyTokenAsync } from '../../../core/actions/authActions'
import { getProcessByPosition } from '../../../core/actions/audioActions'

import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'

import './dashboard.scss'

const beforeYouBegain =
    'Please ensure that you are comfortable and have at least an hour of uninterrupted time to complete each process.<br /><br />It is imperative that you not be driving, operating any equipment or doing anything else that will distract your attention during this time.<br /><br />Once started, there is nothing that you have to do except relax.<br /><br />Click the button below to proceed to the payment screen. Once your payment has been received, the processes will be unlocked.'
const welcomeContent = {
    title: 'Welcome!',
    subTitle: 'You are about to take the first step of your journey...',
    heading: 'Before you begin',
    paragraph: beforeYouBegain,
}
const returnContent = {
    title: 'Welcome Back!',
    subTitle: 'You are about to take the next step of your journey...',
    heading: 'Before you begin',
    paragraph: beforeYouBegain,
}
const endContent = {
    title: 'Congratulations!',
    subTitle: 'You have completed all currently available steps.',
    heading: 'More will be added soon.',
    paragraph:
        "Please come back later or visit <a href='https://medizate.com/' rel='noopener noreferrer' target='_blank' >Medizate.com</a> for guided visualization & meditation that complement the processes.",
    last: true,
}


// const paypalID =
//     window.location.host === 'localhost:3000'
//         ? 'AdNDMaVkyqEDxyhZTeHddaPpYsrnuuvSsEvVyYR51TE0Sunm2miEHIfpn1taTITtXzG6yIZjkjFOjCnY'
//         : 'AcZBpvaJMnocyBf55ujq5HyK6MZt3V4DbUD2Lw2xKPdBSZVmPQ__wE8JyuMUN2LQiO13BOrai0kDNOnL'


const getPaypalID = (comingFrom) => {

    const PAYPALIDS = {
        TWFI: {
            DEV: 'AdNDMaVkyqEDxyhZTeHddaPpYsrnuuvSsEvVyYR51TE0Sunm2miEHIfpn1taTITtXzG6yIZjkjFOjCnY',
            PROD: 'AcZBpvaJMnocyBf55ujq5HyK6MZt3V4DbUD2Lw2xKPdBSZVmPQ__wE8JyuMUN2LQiO13BOrai0kDNOnL'
        },
        MEDI: {
            DEV: 'AZ6XyU5s3ICv8EcCR7HScambOFd31iSl0cavivGwgCYJTsmOpJEHWLO8t0itrDaDnx-Nh4fYJRA-pUMk',
            PROD: 'AcaxWxT09ceM2Nz5tOC7b0TY0CxkE1afggFiEfzTemoQab0ubVLWa_KVJ-N_eK2zhAj9JxOC_OvFeSux'
        }
    }

    // DEFAULT TO TWFI PROD
    let paypalID = PAYPALIDS.TWFI.PROD;

    if (window.location.host === 'localhost:3000') {
        paypalID = PAYPALIDS.TWFI.DEV;
    } else {
        if (comingFrom === 'MEDI') {
            paypalID = PAYPALIDS.MEDI.PROD;
        }
    }

    return { 'client-id': paypalID }
}


function Dashboard() {
    const dispatch = useDispatch()
    const authObj = useSelector((state) => state.authReducer)

    const { token, expiredAt, isAuthenticated } = authObj

    const [userData, setUserData] = useState({})
    const [introContent, setIntroContent] = React.useState({})
    const [stepContent, setStepContent] = React.useState({})
    const [updateContent, setUpdateContent] = React.useState(false)
    const [disablePayment, setDisablePayment] = useState(false)

    // SET TIMER TO RENEW TOKEN
    useEffect(() => {
        setAuthToken(token)
        const verifyTokenTimer = setTimeout(() => {
            dispatch(verifyTokenAsync(true))
        }, moment(expiredAt).diff() - 10 * 1000)
        return () => {
            clearTimeout(verifyTokenTimer)
        }
    }, [dispatch, expiredAt, token])

    // GET THE USERS INFO AND STEP CONTENT
    useEffect(() => {
        // GET THE USERS INFORMATION
        const getUser = async () => {
            const result = await getUserService()
            if (result.error) {
                dispatch(verifyTokenEnd())
                if ([401, 403].includes(result.response.status)) dispatch(userLogout())
                return
            }
            setUserData({ ...result.data.user })
        }

        // GET THE CONTENT FOR THE STEP THE USER IS CURRENTLLY ON
        const getProcess = async () => {
            const result = await getProcessByPosition({ position: userData.process, gender: userData.gender })

            if (result.error) {
                // This happens when the users current process number is > the number or processes available
                setIntroContent(endContent)

                // We should never need the below because the user will never make it past step one if there is no data for the current process number
                setStepContent({
                    title: 'Congratulations!',
                    subTitle: 'You have completed all currently available steps.',
                    audioID: '',
                })
            } else {
                setIntroContent(userData.process === 1 ? welcomeContent : returnContent)
                setStepContent(result.data.data)
            }
            setUpdateContent(false)
        }

        if (Object.keys(userData).length === 0) {
            getUser()
        } else if (Object.keys(introContent).length === 0 || updateContent) {
            getProcess()
        }
    }, [dispatch, setUserData, userData, setIntroContent, introContent, setStepContent, stepContent, updateContent])

    const processPayment = async (userId, orderData) => {
        const result = await processPaypalPayment({ userId, orderData })

        if (!result.data.success) {
            Swal.fire('Oops!', 'Sorry, we could not verify payment. please contact info@rethinkmain.com for help.', 'warning')
        }

        Swal.fire({
            icon: 'success',
            title: 'Payment Processed!',
            text: 'Thank you for your payment',
            confirmButtonText: 'Lets Begin',
        }).then((result) => {
            window.location.reload(false)
        })
    }

    if (!isAuthenticated) {
        return <Redirect to={{ pathname: '/' }} />
    }

    return (
        <Wrapper>
            <Header userData={userData} />
            <div className="dashboard">
                {
                    // welcoming step
                    userData.step === 1 && (
                        <Container>
                            <Row className="justify-content-md-center">
                                <Col sm={10} className="contentBox paddingBox">
                                    <div className="contentHeader">
                                        <h3>{introContent.title}</h3>
                                        <p className="text-center">{introContent.subTitle}</p>
                                    </div>
                                    <div className="body">
                                        <p className="introHeading">{introContent.heading}</p>
                                        <p className="bodyContent" dangerouslySetInnerHTML={{ __html: introContent.paragraph }}></p>
                                    </div>
                                    {/* <div className="text-center mt-5">{introContent.last ? <PlaylistButton /> : <PayButton userData={userData} />}</div> */}
                                    <div className="text-center mt-5">
                                        {introContent.last ? (
                                            ''
                                        ) : (
                                            <div>
                                                {/* <PayButton userData={userData} /> */}
                                                <h4>Lets Begin</h4>
                                                <br />
                                                {/* <PayPalScriptProvider options={{ 'client-id': paypalID }}> */}
                                                <PayPalScriptProvider options={getPaypalID(userData.comingFrom)}>
                                                    <PayPalButtons
                                                        disabled={disablePayment}
                                                        style={{ layout: 'vertical' }}
                                                        createOrder={(data, actions) => {
                                                            return actions.order.create({
                                                                purchase_units: [{ description: 'The Processes', amount: { currency_code: 'USD', value: 40 } }],
                                                            })
                                                        }}
                                                        onApprove={(data, actions) => {
                                                            return actions.order.capture().then((orderData) => {
                                                                setDisablePayment(true)
                                                                processPayment(userData.userId, orderData)
                                                            })
                                                        }}
                                                        onError={(err) => {
                                                            setDisablePayment(true)
                                                            alert('PayPal Error')
                                                        }}
                                                    />
                                                </PayPalScriptProvider>
                                            </div>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    )
                }

                {
                    // step with player
                    userData.step >= 2 && <Step userData={userData} setUserData={setUserData} stepContent={stepContent} setUpdateContent={setUpdateContent} />
                }
            </div>
            <Footer />
        </Wrapper>
    )
}

export default Dashboard
